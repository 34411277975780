import './App.css';
import React from 'react'
import { Pane } from 'evergreen-ui'

import Curl from './Curl.js';

function App() {
    return (
        <Curl />
    )
}

export default App;
