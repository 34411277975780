import './App.css';
import React, { useReducer, useState, useEffect } from 'react'
import { Pane, Heading, TextInputField, SelectField, TextareaField, Button, GlobeIcon, HistoryIcon, SidebarTab, Tab, Tablist, Table, Tooltip, Position, Small, Text, Code, Paragraph, InlineAlert } from 'evergreen-ui'
import API from './Api.js';

var regionHumanReadable = {
    "us-east-2": "US East (Ohio)",
    "us-east-1": "US East (N. Virginia)",
    "us-west-1": "US West (N. California)",
    "us-west-2": "US West (Oregon)",
    "af-south-1": "Africa (Cape Town)",
    "ap-east-1": "Asia Pacific (Hong Kong)",
    "ap-south-1": "Asia Pacific (Mumbai)",
    "ap-northeast-3": "Asia Pacific (Osaka)",
    "ap-northeast-2": "Asia Pacific (Seoul)",
    "ap-southeast-1": "Asia Pacific (Singapore)",
    "ap-southeast-2": "Asia Pacific (Sydney)",
    "ap-northeast-1": "Asia Pacific (Tokyo)",
    "ca-central-1": "Canada (Central)",
    "eu-central-1": "Europe (Frankfurt)",
    "eu-west-1": "Europe (Ireland)",
    "eu-west-2": "Europe (London)",
    "eu-south-1": "Europe (Milan)",
    "eu-west-3": "Europe (Paris)",
    "eu-north-1": "Europe (Stockholm)",
    "me-south-1": "Middle East (Bahrain)",
    "sa-east-1": "South America (São Paulo)"
}

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Curl() {
    // Form data
    const [frequency, setFrequency] = React.useState('5')
    const [formData, setFormData] = useReducer(formReducer, {});

    // Progress of request
    const [waiting, setWaiting] = useState(false);
    const [noticeError, setNoticeError] = useState(false);
    const [noticeErrorReason, setNoticeErrorReason] = useState("");
    const [noticeDone, setNoticeDone] = useState(false);
    const [noticeDetailDone, setNoticeDetailDone] = useState(false);

    // Response data & metadata
    const [responseData, setResponseData] = useState([])
    const [responseTimeData, setResponseTimeData] = useState([])
    const [responseID, setResponseID] = useState([])

    // Selected panel & tab
    const [selectedInputCurl, setSelectedInputCurl] = React.useState(0) // use on result panels

    const [selectedIndex, setSelectedIndex] = React.useState(0) // use on result panels
    const [executionTabs] = React.useState(['Response Time', 'Timing Analysis'])

    const [selectedStageIndex, setSelectedStageIndex] = React.useState(0)
    const [stageTabs] = React.useState(['Execute', 'History'])

    const [curlType] = React.useState(['Basic', 'Full'])

    // History
    const [licenseHistoryValue, setLicenseHistoryValue] = React.useState('')
    const [responseHistoryID, setResponseHistoryID] = useState([])
    const [noticeHistoryDone, setNoticeHistoryDone] = useState(false);
    const [resultOfExcutionID, setResultOfExcutionID] = useState(false);

    // LocalStorage
    const [localID, setLocalID] = React.useState('');

    const handleExecutionSubmit = event => {
        event.preventDefault();

        setWaiting(true);
        setNoticeDone(false);
        setNoticeDetailDone(false);
        setNoticeError(false);
        setNoticeErrorReason("");

        setResultOfExcutionID(false);
        setNoticeDone(false);

        setResponseData([]);
        setResponseTimeData([]);
        setResponseID([]);
        let responseArray = [];
        let responseTimeArray = [];
        let responseIDArray = [];

        //let random_array = new Uint32Array(1);
        //window.crypto.getRandomValues(random_array);
        //let executeID = random_array[0];
        
        let dateTime = Date.now();
        let executeID = Math.floor(dateTime / 1000);

        let promisesRequest = [];
        for (let i = 1; i <= frequency; i++) {
            promisesRequest.push(
                API.post(`/curl/global-executing`, {
                    serial_key: formData['license'],
                    curl_command_base64: btoa(formData['curl'].toString()),
                    execute_id: executeID.toString()
                })
                    .then(response => {
                        if (response.data.status === false) {
                            setWaiting(false);
                            console.log("Error", response.data.reason);
                            setNoticeError(true);
                            setNoticeErrorReason(response.data.reason);
                            return false;
                        } else {
                            responseArray.push(response);
                            responseTimeArray.push(Object.fromEntries(Object.entries(response.data.response_time).sort().reverse()));
                            responseIDArray.push(response.data.runner_id);
                            console.log("Pushing request ID to array", response.data.runner_id);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        setWaiting(false);
                        setNoticeError(true);
                    })
            )
        }

        setLocalID("executeID-" + executeID.toString());

        Promise.all(promisesRequest).then(() => {
            setResponseData(responseArray);
            setResponseTimeData(responseTimeArray);
            console.log("responseTimeArray", responseTimeArray);
            setNoticeDone(true);
        }).then(() => {
            console.log("responseArray", responseArray);
            let promisesRequestDetail = [];
            for (let i = 0; i < frequency; i++) {
                promisesRequestDetail.push(
                    API.post(`/curl/runner-detail`, {
                        serial_key: formData['license'],
                        execute_id: executeID.toString(),
                        runner_id: responseIDArray[i],
                    })
                        .then(responseDetail => {
                            console.log("Number of region: ", responseDetail.data.number_of_region + 1 );
                            setResponseID(oldArray => [...oldArray, responseDetail.data]);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                )
            }
            Promise.all(promisesRequestDetail).then(() => {
                API.post(`/curl/execution`, {
                    serial_key: formData['license'],
                    execute_id: executeID.toString(),
                    method: "add",
                    curl_endpoint: responseArray[0]["data"]["curl_metadata"]["url_effective"],
                    curl_method: responseArray[0]["data"]["curl_metadata"]["method"]
                })
                setNoticeDetailDone(true);
                setWaiting(false);
            }).then(() => {
                sleep(200).then(() => {
                    console.log("localID", localID);
                    console.log("responseID", responseID);
                })
            })
        })
    }

    const handleExecutionChangeBasic = event => {
        setFormData({
            name: event.target.name,
            value: 'curl ' + event.target.value,
        });
    }

    const handleExecutionChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const handleHistoryChange = event => {
        event.preventDefault();
        setWaiting(true);
        setNoticeHistoryDone(false);
        setResponseHistoryID([]);

        API.get(`/curl/execution`, {
            params: {
                serial_key: licenseHistoryValue.toString(),
                method: "list"
            }
        })
            .then(response => {
                setResponseHistoryID(response.data.executions);
                sleep(200).then(() => {
                    setWaiting(false);
                    setNoticeHistoryDone(true);
                    console.log("List executions", response.data.executions);
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const selectExecutionID = (id, key, error) => {
        setNoticeError(false);
        setNoticeErrorReason("");

        setResponseData([]);
        setResponseTimeData([]);
        setResponseID([]);

        let responseArray = [];
        let responseTimeArray = [];

        setResultOfExcutionID(false);
        setNoticeDone(false)
        console.log("Select execution_id: ", id, " on serial key: ", key);
        let promisesHistoryRequest = [];
        API.get(`/curl/runners`, {
            params: {
                serial_key: key.toString(),
                execute_id: id.toString()
            }
        })
            .then(response => {
                console.log("Selected execute id", response.data.detail_in_each_runners);

                for (let i = 0; i < frequency; i++) {
                    responseTimeArray.push(Object.fromEntries(Object.entries(response.data.detail_in_each_runners[i].response_time).sort().reverse()));
                    responseArray.push(response.data.detail_in_each_runners[i]);

                    promisesHistoryRequest.push(
                        API.post(`/curl/runner-detail`, {
                            serial_key: key.toString(),
                            execute_id: id.toString(),
                            runner_id: response.data.detail_in_each_runners[i].runner_id,
                        })
                            .then(responseDetail => {
                                setResponseID(oldArray => [...oldArray, responseDetail.data]);
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                    )
                }
                setResponseData(responseArray);
                setResponseTimeData(responseTimeArray);
            })
            .catch(function (error) {
                console.log(error);
            })
        Promise.all(promisesHistoryRequest).then(() => {
            sleep(500).then(() => {
                console.log("responseTimeArray", responseTimeArray);
                console.log("responseArray", responseArray);
                console.log("responseID", responseID);
                setNoticeDone(false);
                setResultOfExcutionID(true);
            })
        })

    }

    useEffect(() => {
        localStorage.setItem(localID, JSON.stringify(responseID));
      }, [responseID]);

    return (
        <Pane clearfix flex-direction="row" display="flex" width="1366px" background="#fcfbf8" marginTop="25px" marginBottom="25px" marginLeft="auto" marginRight="auto">
            <Pane width="455px" order={0} padding="15px" paddingRight="0" height="auto">
                <Heading size={100} marginBottom={16}>Measuring HTTP response time globally</Heading>
                <div>
                    <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                        {stageTabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                id={tab}
                                onSelect={() => setSelectedStageIndex(index)}
                                isSelected={index === selectedStageIndex}
                                background="#f7fafc"
                                aria-controls={`panel-${tab}`}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                    <Pane flex="1">
                        <Pane
                            key="Execute"
                            id={`panel-Execute`}
                            role="tabpanel"
                            aria-labelledby="Execute"
                            aria-hidden={0 !== selectedStageIndex}
                            display={0 === selectedStageIndex ? 'block' : 'none'}
                        >
                            <div>
                                <form onSubmit={handleExecutionSubmit}>
                                    <TextInputField id="app-license-input"
                                        name="license"
                                        required
                                        onChange={handleExecutionChange}
                                        description="Demo key for Alpha testing: 4e2f1b63-f629-4485-9e23-3989e181a2b4"
                                        label="License key" placeholder="Input your license key here...." />
                                    <SelectField id="app-number-of-request"
                                        name="frequency"
                                        value={frequency}
                                        display="none"
                                        required
                                        onChange={event => setFrequency(event.target.value)}
                                        description="Number of curl request will be executed on each region"
                                        label="Request Frequency" >
                                        <option value="1">1 request</option>
                                        <option value="2">2 requests</option>
                                        <option value="3">3 requests</option>
                                        <option value="4">4 requests</option>
                                        <option value="5" selected>5 requests</option>
                                    </SelectField>

                                    <Heading size={400} marginBottom={10}>cURL endpoint type *</Heading>
                                    <Pane display="flex">
                                        <Tablist marginTop={0} flexBasis={50} marginRight={10}>
                                            {curlType.map((type, index) => (
                                                <SidebarTab
                                                    key={type}
                                                    id={type}
                                                    onSelect={() => setSelectedInputCurl(index)}
                                                    isSelected={index === selectedInputCurl}
                                                    aria-controls={`panel-${type}`}
                                                >
                                                    {type}
                                                </SidebarTab>
                                            ))}
                                        </Tablist>
                                        <Pane flex="1" >
                                            <Pane 
                                                key="Basic"
                                                id={`panel-Basic`}
                                                role="tabpanel"
                                                aria-labelledby="Basic"
                                                aria-hidden={0 !== selectedInputCurl}
                                                display={0 === selectedInputCurl ? 'block' : 'none'}
                                            >
                                                <TextareaField id="app-curl-input"
                                                    name="curl"
                                                    marginTop={-10}
                                                    marginBottom={10}
                                                    inputHeight={322}
                                                    onChange={handleExecutionChangeBasic}
                                                    hint="For example: https://troubleshoot.network/"
                                                    placeholder="Simple https endpoint" />

                                                <Button type='submit'
                                                    float='right'
                                                    iconAfter={GlobeIcon}
                                                    isLoading={waiting}
                                                    appearance="primary" intent="success">
                                                    Execute</Button>
                                            </Pane>
                                            <Pane
                                                key="Full cURL"
                                                id={`panel-Advanced`}
                                                role="tabpanel"
                                                aria-labelledby="Full cURL"
                                                aria-hidden={1 !== selectedInputCurl}
                                                display={1 === selectedInputCurl ? 'block' : 'none'}
                                            >
                                                <TextareaField id="app-curl-input"
                                                    name="curl"
                                                    marginTop={-10}
                                                    marginBottom={10}
                                                    inputHeight={322}
                                                    onChange={handleExecutionChange}

                                                    placeholder="Open your site, select request which returns 200 on tab Network of devTools, copy as cURL, then paste it here."
                                                    hint="For example: curl -X POST -d {'message':'The force is strong with this one...'} -H 'Content-Type: application/json' https://10c67359f39656ae6142cf2ba26258ff.m.pipedream.net" />

                                                <Button type='submit'
                                                    float='right'
                                                    iconAfter={GlobeIcon}
                                                    isLoading={waiting}
                                                    appearance="primary" intent="success">
                                                    Execute</Button>
                                            </Pane>
                                        </Pane>
                                        
                                    </Pane>


                                </form>
                            </div>
                        </Pane>
                        <Pane
                            key="History"
                            id={`panel-History`}
                            role="tabpanel"
                            aria-labelledby="History"
                            aria-hidden={1 !== selectedStageIndex}
                            display={1 === selectedStageIndex ? 'block' : 'none'}
                        >
                            <div>
                                <Pane display="flex">
                                    <TextInputField id="app-license-input"
                                        name="license"
                                        flex={1}
                                        required
                                        onChange={e => setLicenseHistoryValue(e.target.value)}
                                        label="License key" placeholder="Input your license key here...." />
                                    <Button type='submit'
                                        float='right'
                                        marginRight={0}
                                        marginTop={26}
                                        marginBottom={10}
                                        marginLeft={10}
                                        iconAfter={HistoryIcon}
                                        isLoading={waiting}
                                        onClick={handleHistoryChange}
                                        appearance="primary" intent="success">
                                        Check</Button>
                                </Pane>

                                {noticeHistoryDone &&
                                    <div>
                                        <Heading size={400} marginBottom={10}>History</Heading>
                                        <Table>
                                            <Table.Head height="auto" paddingY={5} background="green100">
                                                <Table.TextCell flexGrow={1} paddingRight={0}>Method</Table.TextCell>
                                                <Table.TextCell flexGrow={6} paddingLeft={0}>Detail</Table.TextCell>
                                            </Table.Head>
                                            <Table.Body maxHeight={741}>
                                                {
                                                    Object.keys(responseHistoryID).map(function (element, index) {
                                                        return (
                                                            <div>
                                                                <Table.Row height="auto" paddingY={5} isSelectable onSelect={(e) => selectExecutionID(responseHistoryID[element].execute_id, responseHistoryID[element].serial_key, e)}>
                                                                    <Table.TextCell flexGrow={1} paddingRight={0}>{responseHistoryID[element].curl_method}</Table.TextCell>
                                                                    <Tooltip content={<Paragraph height="auto" display="flex" alignItems="left" justifyContent="left"> UTC: {responseHistoryID[element].timestamp}</Paragraph>} position={Position.RIGHT} appearance="card">
                                                                        <Table.TextCell flexGrow={6} paddingLeft={0}>{responseHistoryID[element].curl_endpoint}</Table.TextCell>
                                                                    </Tooltip>
                                                                </Table.Row>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                }
                            </div>
                        </Pane>
                    </Pane>

                </div>


            </Pane>
            <Pane width="911px" order={1} padding="15px" height="auto">
                <Heading size={100} marginBottom={16}>Result</Heading>
                {noticeError && <InlineAlert intent="warning">{noticeErrorReason}</InlineAlert>}
                {noticeHistoryDone && resultOfExcutionID && !noticeError &&
                    <div>
                        <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                            {executionTabs.map((tab, index) => (
                                <Tab
                                    key={tab}
                                    id={tab}
                                    onSelect={() => setSelectedIndex(index)}
                                    isSelected={index === selectedIndex}
                                    background="#f7fafc"
                                    aria-controls={`panel-${tab}`}
                                >
                                    {tab}
                                </Tab>
                            ))}
                        </Tablist>
                        <Pane flex="1">
                            <Pane
                                key="Response Time"
                                id={`panel-Response-Time`}
                                role="tabpanel"
                                aria-labelledby="Response Time"
                                aria-hidden={0 !== selectedIndex}
                                display={0 === selectedIndex ? 'block' : 'none'}
                            >
                                <div>
                                    <Heading size={200} marginBottom={10}>Unit: second</Heading>
                                    <Table>
                                        <Table.Head height="auto" paddingY={5} background="green100">
                                            <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>Region Code</Table.TextCell>
                                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>Region</Table.TextCell>
                                            {
                                                Object.keys(responseTimeData).map(function (element, index) {
                                                    return (
                                                        <Table.TextHeaderCell>Request {index + 1}</Table.TextHeaderCell>
                                                    );
                                                })
                                            }
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                Object.keys(responseTimeData[0]).map(function (element, index) {
                                                    if ( responseTimeData ) {
                                                        return (
                                                            <div>
                                                                <Table.Row height="auto" paddingY={5} isSelectable>
                                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>{element}</Table.TextCell>
                                                                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>{regionHumanReadable[element]}</Table.TextCell>
                                                                    {
                                                                        Object.keys(responseTimeData).map(function (childElement, childIndex) {
                                                                            if ( responseTimeData[childIndex][element] ) {
                                                                                return (
                                                                                    <Table.TextCell>{responseTimeData[childIndex][element]}</Table.TextCell>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <Table.TextCell>-</Table.TextCell>
                                                                                )
                                                                            }
                                                                            
                                                                        })
                                                                    }
                                                                </Table.Row>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div>unknown</div>
                                                        )
                                                    }
                                                    
                                                })
                                            }
                                        </Table.Body>
                                    </Table>
                                    <Heading size={200} marginBottom={10} marginTop={15}>cURL request metadata</Heading>
                                    <Table marginBottom={16} width={600}>
                                        <Table.Head height="auto" paddingY={5} background="green100">
                                            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>metadata</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Value</Table.TextHeaderCell>
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                Object.keys(responseData[0]["curl_metadata"]).map(function (element) {
                                                    return (
                                                        <div>
                                                            <Table.Row height="auto" paddingY={5} isSelectable>
                                                                <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>{element}</Table.TextCell>
                                                                <Table.TextCell>{responseData[0]["curl_metadata"][element]}</Table.TextCell>
                                                            </Table.Row>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </Pane>
                            <Pane
                                key="Timing Analysis"
                                id={`panel-Timing-Analysis`}
                                role="tabpanel"
                                aria-labelledby="Timing Analysis"
                                aria-hidden={1 !== selectedIndex}
                                display={1 === selectedIndex ? 'block' : 'none'}
                            >
                                {
                                    Object.keys(responseTimeData[0]).map(function (element, index) {
                                        return (
                                            <div>

                                                <Heading size={200} marginBottom={10}>{regionHumanReadable[element]} - {element}</Heading>
                                                <Table marginBottom={16}>
                                                    <Table.Head height="auto" paddingY={5} background="green100" display="flex">
                                                        <Table.TextCell flexGrow={0.4} >R</Table.TextCell>
                                                        <Table.TextCell flexGrow={7} >time_namelookup</Table.TextCell>
                                                        <Table.TextCell flexGrow={6} >time_connect</Table.TextCell>
                                                        <Table.TextCell flexGrow={7} >time_appconnect</Table.TextCell>
                                                        <Table.TextCell flexGrow={7} >time_pretransfer</Table.TextCell>
                                                        <Table.TextCell flexGrow={8} >time_starttransfer</Table.TextCell>
                                                        <Table.TextCell flexGrow={5} >time_total</Table.TextCell>
                                                    </Table.Head>
                                                    <Table.Body>

                                                        {
                                                            Object.keys(responseID).map(function (childElement, childIndex) {
                                                                if ( responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)] ) {
                                                                    return (
                                                                        <Table.Row height="auto" paddingY={5} isSelectable>
                                                                            <Table.TextCell flexGrow={0.4}>{childIndex + 1}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_namelookup']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={6}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_connect']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_appconnect']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_pretransfer']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={8}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_starttransfer']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={5}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_total']}</Table.TextCell>
                                                                        </Table.Row>
                                                                    )
                                                                    
                                                                } else {
                                                                    return (
                                                                        <Table.Row height="auto" paddingY={5} isSelectable>
                                                                            <Table.TextCell flexGrow={0.4}>{childIndex + 1}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={6}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={8}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={5}>-</Table.TextCell>
                                                                        </Table.Row>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </Table.Body>
                                                </Table>

                                            </div>
                                        );
                                    })
                                }
                            </Pane>
                        </Pane>
                    </div>
                }
                {noticeDone && noticeDetailDone && !noticeError &&
                    <div>
                        <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                            {executionTabs.map((tab, index) => (
                                <Tab
                                    key={tab}
                                    id={tab}
                                    onSelect={() => setSelectedIndex(index)}
                                    isSelected={index === selectedIndex}
                                    background="#f7fafc"
                                    aria-controls={`panel-${tab}`}
                                >
                                    {tab}
                                </Tab>
                            ))}
                        </Tablist>
                        <Pane flex="1">
                            <Pane
                                key="Response Time"
                                id={`panel-Response-Time`}
                                role="tabpanel"
                                aria-labelledby="Response Time"
                                aria-hidden={0 !== selectedIndex}
                                display={0 === selectedIndex ? 'block' : 'none'}
                            >
                                <div>
                                    <Heading size={200} marginBottom={10}>Unit: second</Heading>
                                    <Table>
                                        <Table.Head height="auto" paddingY={5} background="green100">
                                            <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>Region Code</Table.TextCell>
                                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>Region</Table.TextCell>
                                            {
                                                Object.keys(responseTimeData).map(function (element, index) {
                                                    return (
                                                        <Table.TextHeaderCell>Request {index + 1}</Table.TextHeaderCell>
                                                    );
                                                })
                                            }
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                Object.keys(responseTimeData[0]).map(function (element, index) {
                                                    return (
                                                        <div>
                                                            <Table.Row height="auto" paddingY={5} isSelectable>
                                                                <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>{element}</Table.TextCell>
                                                                <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>{regionHumanReadable[element]}</Table.TextCell>
                                                                {
                                                                    Object.keys(responseTimeData).map(function (childElement, childIndex) {
                                                                        return (
                                                                            <Table.TextCell>{responseTimeData[childIndex][element]}</Table.TextCell>
                                                                        )
                                                                    })
                                                                }
                                                            </Table.Row>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </Table.Body>
                                    </Table>
                                    <Heading size={200} marginBottom={10} marginTop={15}>cURL request metadata</Heading>
                                    <Table marginBottom={16} width={600}>
                                        <Table.Head height="auto" paddingY={5} background="green100">
                                            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>metadata</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Value</Table.TextHeaderCell>
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                Object.keys(responseData[0]["data"]["curl_metadata"]).map(function (element) {
                                                    return (
                                                        <div>
                                                            <Table.Row height="auto" paddingY={5} isSelectable>
                                                                <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>{element}</Table.TextCell>
                                                                <Table.TextCell>{responseData[0]["data"]["curl_metadata"][element]}</Table.TextCell>
                                                            </Table.Row>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </Pane>
                            <Pane
                                key="Timing Analysis"
                                id={`panel-Timing-Analysis`}
                                role="tabpanel"
                                aria-labelledby="Timing Analysis"
                                aria-hidden={1 !== selectedIndex}
                                display={1 === selectedIndex ? 'block' : 'none'}
                            >
                                {
                                    Object.keys(responseTimeData[0]).map(function (element, index) {
                                        return (
                                            <div>

                                                <Heading size={200} marginBottom={10}>{regionHumanReadable[element]} - {element}</Heading>
                                                <Table marginBottom={16}>
                                                    <Table.Head height="auto" paddingY={5} background="green100" display="flex">
                                                        <Table.TextCell flexGrow={0.4} >R</Table.TextCell>
                                                        <Table.TextCell flexGrow={7} >time_namelookup</Table.TextCell>
                                                        <Table.TextCell flexGrow={6} >time_connect</Table.TextCell>
                                                        <Table.TextCell flexGrow={7} >time_appconnect</Table.TextCell>
                                                        <Table.TextCell flexGrow={7} >time_pretransfer</Table.TextCell>
                                                        <Table.TextCell flexGrow={8} >time_starttransfer</Table.TextCell>
                                                        <Table.TextCell flexGrow={5} >time_total</Table.TextCell>
                                                    </Table.Head>
                                                    <Table.Body>

                                                        {
                                                            Object.keys(responseID).map(function (childElement, childIndex) {
                                                                if ( responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)] ) {
                                                                    return (
                                                                        <Table.Row height="auto" paddingY={5} isSelectable>
                                                                            <Table.TextCell flexGrow={0.4}>{childIndex + 1}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_namelookup']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={6}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_connect']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_appconnect']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_pretransfer']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={8}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_starttransfer']}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={5}>{responseID[childIndex]['detail_in_each_region'][responseID[childIndex]['detail_in_each_region'].map(function (e) { return e.region; }).indexOf(element)]['time_total']}</Table.TextCell>
                                                                        </Table.Row>
                                                                    )
                                                                    
                                                                } else {
                                                                    return (
                                                                        <Table.Row height="auto" paddingY={5} isSelectable>
                                                                            <Table.TextCell flexGrow={0.4}>{childIndex + 1}</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={6}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={7}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={8}>-</Table.TextCell>
                                                                            <Table.TextCell flexGrow={5}>-</Table.TextCell>
                                                                        </Table.Row>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </Table.Body>
                                                </Table>

                                            </div>
                                        );
                                    })
                                }
                            </Pane>
                        </Pane>
                    </div>
                }
            </Pane>
        </Pane>
    )
}

export default Curl;
